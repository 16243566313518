import { useContext, useEffect, useState } from "react";
import {
  BuyContainer,
  BuyInner,
  BuyLeft,
  BuyRight,
  BuyWrapper,
  FormBalanceLeft,
  FormBalanceList,
  FormBalanceReceive,
  FormBalanceRight,
  FormBalanceTextInfo,
  FormBalanceTotal,
  FormBuy,
  FormPayment,
  FormRecevie,
  FormRow,
  FormRowBalance,
} from "./styled";
import { OniBotTitle } from "../Bot/styled";
import {
  LiquidityActions,
  LiquidityInfo,
  LiquidityQuote,
} from "../Liquidity/Add/styled";
import { ContextProviderWrapper } from "../../components/Context";
import { ButtonCommon } from "../../Layout/styled";
import { SwapChartHeader } from "../Swap/styled";
import ProfileCommon from "../../components/Common/Profile";
import Web3 from "web3";
import abiTokenDefault from "../../contract/ABI/ABI_NFT.json";
import { useAccount, useBalance } from "wagmi";
import InputCommon from "../../components/Common/Input";
import {
  convertFixed,
  convertFromWei,
  convertToWei,
} from "../../utils/convertNumber";
import {
  CurrencyAmount,
  Fetcher,
  Percent,
  Route,
  Token,
  Trade,
} from "@pancakeswap/sdk";
import { ethers } from "ethers";
import { addressContractLP, addressContractUSDT } from "../../contract/Address";
import { lpABI } from "../../contract/Pair";
import toast from "react-hot-toast";
import LoadingCommon from "../../components/Loading";
import { Spin } from "antd";
import ChartCommon from "../../components/Common/Chart";
import { useSelector } from "react-redux";
import { formatNumberMega } from "../../utils/formatNumber";
import { gql, useMutation } from "@apollo/client";
import { useWeb3Modal } from "@web3modal/wagmi/react";
declare const window: Window & typeof globalThis & { ethereum: any };

const BUY_OTC_MUTATION = gql`
  mutation buyOTC($hash: String!) {
    buyOTC(hash: $hash)
  }
`;

const Buy = () => {
  const { open } = useWeb3Modal();
  const { isDesktop, isMobile } = useContext(ContextProviderWrapper)!;
  const { address } = useAccount();
  const [inputFor, setInputFor] = useState("A");
  const [valueA, setValueA] = useState<any>("");
  const [valueB, setValueB] = useState<any>("");
  const [loadingBuy, setLoadingBuy] = useState(false);
  const [tokenFrom, setTokenFrom] = useState<any>();
  const [tokenTo, setTokenTo] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [priceBnb, setPriceBnb] = useState<any>("");
  const [swapDetails, setSwapDetails] = useState({
    minReceived: "",
    feeSaved: "",
    priceImpact: "",
    tradingFee: "",
    swapRoute: "",
  });
  const [clickedSelection, setClickedSelection] = useState("BNB");
  const [hasToken, setHasToken] = useState("");
  const [buyOTC] = useMutation(BUY_OTC_MUTATION);
  const [switchSwap, setSwitchSwap] = useState(true);
  const poolInfoData = useSelector((state: any) => state.user.poolInfo);
  const providerRPC = new ethers.JsonRpcProvider(
    "https://bsc-dataseed1.ninicoin.io/"
  );
  const web3 = new Web3(window.ethereum);
  const contractTokenFrom: any = new web3.eth.Contract(
    abiTokenDefault as any,
    "0x2B426cAe6F8760DA4eB04107160d09A857b45109"
  );

  // get usd to bnb
  const converUsdToBNB = () => {
    if (valueA && poolInfoData?.quote_token_price_usd) {
      const bnbAmount = valueA / poolInfoData.quote_token_price_usd;
      setPriceBnb(bnbAmount);
    }
  };
  useEffect(() => {
    converUsdToBNB();
  }, [poolInfoData, valueA]);

  // handle buy token
  const sendBNB = async () => {
    setLoadingBuy(true);
    try {
      const transactionParams = {
        from: address,
        to: "0xc3c36b2C650A27D37e10dA0Ad68B70182Bf3CBa8",
        value: web3.utils.toWei((priceBnb * 1.01).toFixed(18), "ether"),
      };
      const gasEstimate = await web3.eth.estimateGas(transactionParams);
      const transaction:any = await web3.eth.sendTransaction({
        ...transactionParams,
        gas: gasEstimate,
      });
      setHasToken(transaction?.transactionHash)
    } catch (error:any) {
      setLoadingBuy(false);
      toast.error(error?.message ? error?.message : 'Purchase failed')
      console.error(error);
    }
  };

  const fetchTokenDecimals = async (tokenAddress: any) => {
    try {
      const tokenContract = new ethers.Contract(
        tokenAddress,
        ["function decimals() view returns (uint8)"],
        providerRPC
      );
      const decimals = await tokenContract.decimals();
      return decimals;
    } catch (error) {
      console.error(
        `Error fetching decimals for token ${tokenAddress}:`,
        error
      );
      return 18;
    }
  };

  const fetchToken = async () => {
    const pairContract = new ethers.Contract(
      addressContractLP,
      lpABI,
      providerRPC
    );

    const tokenAAddress = await pairContract.token0();
    const tokenBAddress = await pairContract.token1();

    const decimalsA = await fetchTokenDecimals(tokenAAddress);
    const decimalsB = await fetchTokenDecimals(tokenBAddress);


    setTokenFrom(
      switchSwap
        ? new Token(
            56,
            tokenBAddress,
            Number(decimalsB.toString()),
            "BNB",
            "Binance Coin"
          )
        : new Token(
            56,
            tokenAAddress,
            Number(decimalsA.toString()),
            "ONI",
            "ONI Protocol"
          )
    );
    setTokenTo(
      switchSwap
        ? new Token(
            56,
            tokenAAddress,
            Number(decimalsA.toString()),
            "ONI",
            "ONI Protocol"
          )
        : new Token(
            56,
            tokenBAddress,
            Number(decimalsB.toString()),
            "BNB",
            "Binance Coin"
          )
    );
  };

  useEffect(() => {
    fetchToken();
  }, [switchSwap, clickedSelection]);

  // calculateAmounts
  const calculateAmounts = async (
    value: string,
    direction: "AtoB" | "BtoA"
  ) => {
    if (!tokenFrom || !tokenTo) {
      console.error(
        "TokenFrom or TokenTo is undefined. Cannot calculate amounts."
      );
      return;
    }
    setIsLoading(true);
    try {

      let trades;
      const pair = await Fetcher.fetchPairData(tokenFrom, tokenTo);
        if (direction === "AtoB") {
          trades = Trade.bestTradeExactIn(
            [pair],
            CurrencyAmount.fromRawAmount(
              tokenFrom,
              ethers.parseUnits(value || "0", tokenFrom.decimals).toString()
            ),
            tokenTo
          );
        } else {
          const outputAmount: any = CurrencyAmount.fromRawAmount(
            tokenTo,
            ethers.parseUnits(value || "0", tokenTo.decimals).toString()
          );
          trades = Trade.bestTradeExactOut([pair], tokenFrom, outputAmount);
        }
      const trade = trades[0];

      if (direction === "AtoB") {
        const amountOut = trade.outputAmount.toSignificant(6);
        setValueB(amountOut);
      } else {
        const amountIn = trade.inputAmount.toSignificant(6);
        setValueA(amountIn);
      }

      calculateSwapDetails(trade, tokenFrom, tokenTo, value);
    } catch (error) {
      console.error("Error calculating amounts:", error);
    }
    setIsLoading(false);
  };
  const calculateSwapDetails = async (
    trade: any,
    tokenA: any,
    tokenB: any,
    priceBnb: any
  ) => {
    try {
      const slippageTolerance = new Percent("50", "10000");

      const minReceived = trade
        .minimumAmountOut(slippageTolerance)
        .toSignificant(6);

      const priceImpact = trade.priceImpact.toFixed(2);
      const tradingFee = `${(parseFloat(priceBnb) * 0.0025).toFixed(6)} ${
        tokenA.symbol
      }`;
      const swapRoute = `${trade.route.path
        .map((token: any) => token.symbol)
        .join(" -> ")}`;

      setSwapDetails({
        minReceived,
        feeSaved: "0 CAKE (-$0)",
        priceImpact,
        tradingFee,
        swapRoute,
      });
    } catch (error) {
      console.error("Error calculating swap details:", error);
    }
  };

  const handleValueChange = (type: string, value: string) => {
    if (type === "A") {
      setInputFor("A");
      setValueA(value);
      const bnbAmount: any = (Number(value) / Number(poolInfoData.quote_token_price_usd))?.toFixed(18);
      calculateAmounts(bnbAmount.toString(), "AtoB");
    } else {
      setInputFor("B");
      setValueB(value);
    }
  };

  // get balance
  const { data: balanceB } = useBalance({
    address,
  });

  //handle change balance
  const handleChangeBalance = (numberPercen: any) => {
    const totalBalance: any = convertFromWei(balanceB?.value, 18);
    const newValueA = Number(
      totalBalance *
        (numberPercen / 100) *
        poolInfoData?.quote_token_price_usd -
        0.5
    ).toString();
    setValueA(newValueA > "0" ? newValueA : "0");
    const bnbAmount: any = (Number(newValueA) / Number(poolInfoData.quote_token_price_usd))?.toFixed(18);
    calculateAmounts(bnbAmount.toString(), "AtoB");
  };

  //api buyOTC
  const handleBuyOTC = async () => {
    try {
      const { data } = await buyOTC({
        variables: {
          hash: hasToken,
        },
      });
      if (data) {
        toast.success("Purchase successful");
        setLoadingBuy(false);
      } else {
        toast.error("Purchase failed");
        setLoadingBuy(false);
      }
    } catch (error:any) {
      console.error("Error executing buyOTC:", error);
      toast.error(error?.message || "An error occurred");
      setLoadingBuy(false);
    }
  };

  useEffect(() => {
    if (hasToken) {
      handleBuyOTC();
    }
  }, [hasToken]);

  return (
    <BuyContainer>
      <BuyWrapper>
        <OniBotTitle>
          <h2>The Key To Open</h2>
          <p>
            Buying
            <LoadingCommon />
            oni token
          </p>
        </OniBotTitle>
        <BuyInner>
          <BuyLeft>
            {!isDesktop && (
              <SwapChartHeader>
                <ProfileCommon
                  avatar="./img/Common/oni_icon.png"
                  name="ONI Token"
                  subName="ONI Protocol"
                />
                <figure>
                  <img
                    width={36}
                    height={36}
                    src="./img/Dashboard/Swap/swap_chart_star.png"
                    alt="icon"
                    loading="lazy"
                  />
                </figure>
              </SwapChartHeader>
            )}
            <ChartCommon />
          </BuyLeft>
          <BuyRight>
            <div className="block-heading">
              <div className="heading-left">
                <h3 className="title-heading">Buy ONI Token</h3>
                <span>See token info</span>
              </div>
              <div className="heading-right">
                <img
                  className="image-decor"
                  src="/img/Dashboard/Buy/buy_img_01.png"
                  width={177}
                  alt="Buy"
                />
              </div>
            </div>
            <FormBuy>
              <FormRow>
                <FormRowBalance>
                  <div className="form-balance-inner">
                    <FormBalanceLeft>
                      <img
                        src="/img/Dashboard/Buy/icn_bnb.png"
                        width={32}
                        height={32}
                        loading="eager"
                        alt="Oni Token"
                      />
                      <div>
                        {/* <p>Buy with</p> */}
                        <h3 className="title">Buy Token</h3>
                      </div>
                    </FormBalanceLeft>
                  </div>
                  <FormBalanceTotal>
                    <InputCommon
                      type="number"
                      placeHolder="0.00"
                      onChange={(e: any) =>
                        handleValueChange("A", e.target.value)
                      }
                      value={valueA}
                    />
                    <span className="dolar">$</span>
                  </FormBalanceTotal>
                  <FormBalanceList>
                    {listPurchase.map((item: any, index: number) => (
                      <li
                        key={index}
                        onClick={() => {
                          handleChangeBalance(item.number);
                        }}
                      >
                        {item.number} %
                      </li>
                    ))}
                  </FormBalanceList>
                  {/* <FormBalanceTextInfo>
                    <span>
                      You will receive an additional 1% with every $ONI buy
                      transaction on Oni Protocol
                    </span>
                  </FormBalanceTextInfo> */}
                </FormRowBalance>
              </FormRow>
              <FormRow>
                <FormBalanceReceive>
                  <FormBalanceRight>
                    <p className="balance-right">BNB Balance</p>
                    <span className="balance-number">
                      {convertFixed(
                        Number(convertFromWei(balanceB?.value, 18))
                      )}
                    </span>
                  </FormBalanceRight>
                  <FormPayment>
                    <p>Payment: </p>
                    <div className="payment-right">
                      <span className="number">
                        {/* {priceBnb ? `~ ${priceBnb}` : "0"} */}
                        {priceBnb ? `~ ${(parseFloat(priceBnb) * 1.01)}` : "0"}
                      </span>
                      <img
                        src="/img/Dashboard/Buy/icn_bnb_02.png"
                        width={32}
                        height={32}
                        loading="eager"
                        alt="Bnb"
                      />
                    </div>
                  </FormPayment>
                  <FormRecevie>
                    <p className="text-receive">You’ll Receive:</p>
                    <div className="recevie-right">
                      <div className="total-number">
                        <p>~ {priceBnb ? valueB : 0}</p>
                        <img
                          src="/img/Dashboard/Buy/icn_oni.png"
                          width={32}
                          height={32}
                          alt="Oni token"
                        />
                      </div>
                    </div>
                  </FormRecevie>
                </FormBalanceReceive>
              </FormRow>
              <LiquidityInfo>
                <div style={{ justifyContent: "center" }}>
                  <p>
                    1 BNB = ~{poolInfoData?.quote_token_price_base_token} ONI
                    {/* <img
                      width={18}
                      height={18}
                      src="./img/Common/exchange_icon.png"
                      alt="icon"
                      loading="lazy"
                    /> */}
                  </p>
                </div>
              </LiquidityInfo>
              <LiquidityQuote>
                <div>
                  <p>Slippage</p>
                  <span>Auto (2%)</span>
                </div>
                <div>
                  <p>Minimum received</p>
                  <span>~ {swapDetails?.minReceived || "--"}</span>
                </div>
                <div>
                  <p>Price Impact</p>
                  <span
                    style={{
                      color:
                        Number(swapDetails?.priceImpact) < 1 &&
                        swapDetails?.priceImpact !== ""
                          ? "#bbf7c6"
                          : Number(swapDetails?.priceImpact) > 49 &&
                            swapDetails?.priceImpact !== ""
                          ? "#f70d0d"
                          : "",
                    }}
                  >
                    {!swapDetails?.priceImpact
                      ? ""
                      : Number(swapDetails?.priceImpact) < 0.01 && " < "}
                    {!swapDetails?.priceImpact
                      ? "--"
                      : Number(swapDetails?.priceImpact) + "%"}
                  </span>
                </div>
                <div>
                  <p>Trading Fee</p>
                  <span>{swapDetails?.tradingFee || "--"}</span>
                </div>
                <div>
                  <p>Route</p>
                  <span>{swapDetails?.swapRoute || "--"}</span>
                </div>
              </LiquidityQuote>
              <LiquidityActions>
                {address ? (
                  <ButtonCommon
                    style={{ width: "100%" }}
                    className="green"
                    onClick={sendBNB}
                    disabled={(priceBnb > 0) ? false : true}
                  >
                    {loadingBuy ? <Spin /> : `Buy ONI`}
                  </ButtonCommon>
                ) : (
                  <ButtonCommon
                    style={{ width: "100%" }}
                    className="green"
                    onClick={() => {
                      open();
                    }}
                  >
                    Connect Wallet
                  </ButtonCommon>
                )}
              </LiquidityActions>
            </FormBuy>
          </BuyRight>
        </BuyInner>
      </BuyWrapper>
    </BuyContainer>
  );
};

const listPurchase = [
  {
    number: 25,
  },
  {
    number: 50,
  },
  {
    number: 75,
  },
  {
    number: 100,
  },
];

export default Buy;
