import styled from "styled-components";
import { OniBotTitle } from "../Bot/styled";

export const BuyContainer = styled.div`
  padding-top: 30px;
`;
export const BuyWrapper = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  ${OniBotTitle} {
    margin-bottom: 50px;
  }
  @media (min-width: 1024.98px) and (max-width: 1350px) {
    max-width: unset;
  }
  @media screen and (max-width: 1024px) {
    max-width: unset;
    ${OniBotTitle} {
      margin-bottom: 60px;
      h2 {
        font-size: 18px;
        margin-bottom: 4px;
      }
      p {
        font-size: 25px;
      }
      img {
        width: 22px;
        height: 22px;
      }
    }
  }
`;
export const BuyInner = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`;
export const BuyLeft = styled.div`
  width: 100%;
  @media screen and (max-width: 1024px) {
    max-width: unset;
    width: calc(100% - 24px);
    border-radius: 8px;
    border: 1px solid var(--Gray-Stroke, #3d3d3d);
    background: linear-gradient(180deg, #0f1112 0%, #151617 100%);
    padding: 12px;
  }
`;
export const BuyRight = styled.div`
  position: relative;
  max-width: 400px;
  width: 100%;
  .block-heading {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
    .title-heading {
      color: #fff;
      font-size: 24px;
      font-weight: 500;
    }
    span {
      color: #9f9f9f;
      font-family: Rubik;
      font-size: 13px;
      font-weight: 500;
      position: relative;
      padding-left: 20px;
      &:before {
        position: absolute;
        content: "";
        top: 50%;
        margin-top: -7px;
        left: 0;
        background: url("/img/Common/hint_icon.svg") center / cover no-repeat;
        width: 14px;
        height: 14px;
      }
    }
    .image-decor {
      position: absolute;
      top: -150px;
      right: 0;
    }
  }
  @media (min-width: 1024.98px) and (max-width: 1350px) {
    .block-heading {
      .image-decor {
        width: 120px;
        top: -80px;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    max-width: unset;
    .block-heading {
      align-items: flex-end;
      margin-bottom: 80px;
      .image-decor {
        top: -50px;
        width: 154px;
        height: 154px;
      }
    }
  }
`;

export const FormBuy = styled.div`
  .ant-spin-dot-holder {
    color: #fff;
  }
`;
export const FormRow = styled.div`
  padding: 20px 16px;
  border-radius: 6px;
  background: #1c1c1c;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;
export const FormRowTitle = styled.p`
  color: #dfdfdf;
  font-size: 14px;
  font-weight: 500;
`;
export const FormRowBalance = styled.div`
  .form-balance-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }
`;
export const FormBalanceLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  & > div {
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background: linear-gradient(220deg, #b4b4b4 11.31%, #636363 83.28%);
      box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset;
      font-size: 12px;
      padding: 2px 5px;
      margin-bottom: 5px;
    }
  }
  .title {
    color: #fff;
    font-size: 15px;
  }
`;
export const FormBalanceRight = styled.div`
  text-align: right;
  .balance-right {
    color: #9f9f9f;
    font-size: 13px;
  }
  .balance-number {
    display: block;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
  }
`;
export const FormPayment = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
  .payment-right {
    display: flex;
    align-items: center;
    gap: 5px;
    span {
      font-size: 17px;
    }
  }
`;
export const FormRecevie = styled.div`
  .recevie-right {
    min-height: 32px;
    flex: 1;
  }
`;
export const FormBalanceTotal = styled.p`
  color: #fff;
  font-size: 30px;
  margin-bottom: 23px;
  position: relative;
  padding-right: 30px;
  .dolar {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -18px;
  }
`;
export const FormBalanceList = styled.ul`
  display: flex;
  gap: 4px;
  margin-bottom: 7px;
  li {
    cursor: pointer;
    width: calc(100% / 4 - 4px);
    border-radius: 4px;
    border: 1px solid #3b3b3b;
    background: var(--Surface-Background, rgba(40, 40, 40, 0.7));
    background-blend-mode: luminosity;
    box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
    backdrop-filter: blur(50px);
    text-align: center;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    padding: 6px;
  }
`;
export const FormBalanceTextInfo = styled.p`
  color: #9f9f9f;
  font-size: 13px;
  text-align: center;
  margin-bottom: 10px;
  span {
    padding-left: 20px;
    position: relative;
    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      background: url("/img/Dashboard/Buy/icon_info.png") center / cover
        no-repeat;
      width: 14px;
      height: 14px;
    }
  }
`;
export const FormBalanceReceive = styled.div`
  .text-receive {
    color: #fff;
    font-size: 15px;
    margin-bottom: 10px;
  }
  .total-number {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: 32px;
  }
`;
