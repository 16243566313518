import { useAccount } from "wagmi";
import HintCommon from "../../../components/Common/Hint";
import InputCommon from "../../../components/Common/Input";
import ProfileCommon from "../../../components/Common/Profile";
import { ButtonCommon, CheckboxCommon } from "../../../Layout/styled";
import {
  LiqFrame,
  LiqFrameHeader,
  LiqPerList,
  LiquidityActions,
  LiquidityAddWrapper,
  LiquidityFrame,
} from "../../Liquidity/Add/styled";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { convertFixed, convertFromWei } from "../../../utils/convertNumber";
import { Checkbox, CheckboxProps, Spin } from "antd";
import {
  BlockInput,
  ConverPrice,
  ListStake,
  StakeCard,
  TextNote,
} from "./styled";
import { Link } from "react-router-dom";
import { useContext, useEffect } from "react";
import { ContextProviderWrapper } from "../../../components/Context";
import BackCommon from "../../../components/Common/Back";
import { useSelector } from "react-redux";

const MiningStake = ({
  priceBnbPool,
  miningData,
  miningId,
  setMiningId,
  valueInput,
  handleFarmingLP,
  setValueInput,
  balanceLP,
  allowance,
  approveFarming,
  backFunction,
  isLoading,
  pendingApprove,
  setAntiMev,
}: any) => {
  const { address } = useAccount();
  const { open } = useWeb3Modal();
  const onChange = (e: any) => {
    setValueInput(e.target.value);
  };
  const { userProfile }: any = useSelector((state: any) => state.user);

  const onChangeCheckBox: CheckboxProps["onChange"] = (e) => {
    setAntiMev(e.target.checked);
  };

  const { isDesktop } = useContext(ContextProviderWrapper)!;

  const handleCheckPage = () => {
    const inputValue = priceBnbPool * valueInput;
    if (inputValue >= 100 && inputValue <= 999) {
      setMiningId(1);
      localStorage.setItem("activeSwiper", "0");
    } else if (inputValue >= 1000 && inputValue <= 4999) {
      setMiningId(2);
      localStorage.setItem("activeSwiper", "1");
    } else if (inputValue >= 5000 && inputValue <= 9999) {
      setMiningId(3);
      localStorage.setItem("activeSwiper", "2");
    } else if (inputValue >= 10000 && inputValue <= 29999) {
      setMiningId(4);
      localStorage.setItem("activeSwiper", "3");
    } else if (inputValue > 29999) {
      setMiningId(5);
      localStorage.setItem("activeSwiper", "4");
    }
  };

  useEffect(() => {
    if (valueInput > 0 && !isDesktop) {
      handleCheckPage();
    }
  }, [valueInput]);

  return (
    <LiquidityAddWrapper>
      {!isDesktop && (
        <>
          <BackCommon
            backFunction={() => {
              backFunction(0);
              setTimeout(() => {
                localStorage.removeItem("activeSwiper");
              }, 2000);
            }}
          />
          {miningData.map((item: any, index: number) => {
            if (item?.id === miningId) {
              return (
                <StakeCard key={index}>
                  <div className="carf-left">
                    <p className="title-card">pONI-BNB LP</p>
                    <div className="card-apr">
                      APR: <span>{item?.apr}</span>
                    </div>
                  </div>
                  <div className="card-right">
                    <img
                      src={item.imgFrom}
                      width={37}
                      height={35}
                      loading="eager"
                      alt={item.pair}
                    />
                    <div>
                      <p className="title-right">{item?.pair}</p>
                      <p className="text-right">
                        ${item?.valueFrom} - ${item?.valueTo}
                      </p>
                    </div>
                  </div>
                </StakeCard>
              );
            }
          })}
        </>
      )}
      <LiquidityFrame>
        <LiqFrame>
          <LiqFrameHeader>
            <ProfileCommon
              avatar="./img/Common/oni_icon.png"
              name="pONI-BNB LP"
              subName="BNB Smart Chain"
            />
            <div>
              <p>Your Balance</p>
              <span>
                {address && Number(balanceLP.toString()) > 0
                  ? convertFromWei(balanceLP.toString(), 18)
                  : 0}
              </span>
            </div>
          </LiqFrameHeader>
          <BlockInput>
            <InputCommon
              minimum={0}
              type="number"
              value={valueInput}
              placeHolder="0.00"
              onChange={onChange}
            />
          </BlockInput>
          <ConverPrice>
            $
            {address && valueInput > 0
              ? convertFixed(priceBnbPool * valueInput)
              : 0}
          </ConverPrice>
          <LiqPerList>
            {perData.map((item, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    const convertNumber = convertFromWei(
                      balanceLP.toString(),
                      18
                    );
                    setValueInput(Number(convertNumber) * item.value);
                  }}
                >
                  <p>{item.text}</p>
                </li>
              );
            })}
          </LiqPerList>
          <CheckboxCommon>
            <Checkbox onChange={onChangeCheckBox} defaultChecked>
              Mining LPs Anti-MEV Bot
            </Checkbox>
          </CheckboxCommon>
          {/* <TextNote>
            <span>The amount you enter affects to the package in the left</span>
          </TextNote> */}
        </LiqFrame>
      </LiquidityFrame>
      <LiquidityActions>
        {(address && userProfile?.wallet?.address) ? (
          <>
            {Number(valueInput) > Number(allowance) ? (
              <>
                <ButtonCommon
                  style={{
                    width: "100%",
                  }}
                  disabled={pendingApprove}
                  className="gray"
                  onClick={() => {
                    approveFarming();
                  }}
                >
                  {pendingApprove ? <Spin /> : "Approve Mining"}
                </ButtonCommon>
              </>
            ) : (
              <>
                <ButtonCommon
                  disabled={
                    isLoading ||
                    Number(convertFromWei(balanceLP.toString(), 18)) <
                      Number(valueInput) ||
                    !valueInput ||
                    Number(valueInput) < 0
                      ? // || priceBnbPool * valueInput < 100
                        true
                      : false
                  }
                  onClick={() => {
                    if (
                      Number(convertFromWei(balanceLP.toString(), 18)) <
                        Number(valueInput) ||
                      !valueInput ||
                      Number(valueInput) < 0 ||
                      isLoading
                      // || priceBnbPool * valueInput < 100
                    ) {
                      <></>;
                    } else {
                      handleFarmingLP();
                    }
                  }}
                  className="green"
                >
                  {isLoading ? (
                    <Spin />
                  ) : (
                    <>
                      {Number(convertFromWei(balanceLP.toString(), 18)) <
                        Number(valueInput) && Number(valueInput) > 0
                        ? "Not enough balance"
                        : !valueInput
                        ? "Please enter value"
                        : Number(valueInput) < 0
                        ? "Invalid value"
                        : // : priceBnbPool * valueInput < 100
                          // ? "Minimum 100$"
                          "Stake"}
                    </>
                  )}
                </ButtonCommon>
              </>
            )}
          </>
        ) : (
          <ButtonCommon
            onClick={() => {
              open();
            }}
            className="green"
          >
            Please Connect Wallet
          </ButtonCommon>
        )}
      </LiquidityActions>
      <ListStake>
        {listStake.map((item: any, index: number) => (
          <li key={index}>
            <Link to={item.link} target="_blank" rel="noreferrer">
              {item.text}
              <img
                src="/img/Dashboard/Mining/icn_external.png"
                width={14}
                height={14}
                loading="eager"
                alt="External"
              />
            </Link>
          </li>
        ))}
      </ListStake>
      <HintCommon />
    </LiquidityAddWrapper>
  );
};

const perData = [
  {
    id: 1,
    value: 0.25,
    text: "25%",
  },
  {
    id: 2,
    value: 0.5,
    text: "50%",
  },
  {
    id: 3,
    value: 0.75,
    text: "75%",
  },
  {
    id: 4,
    value: 1,
    text: "100%",
  },
];
const listStake = [
  {
    text: "Contract Pool",
    link: "https://bscscan.com/address/0x5aDF0C6446A4A50a1A8951aFA6F3bF998071242d",
  },
  {
    text: "Contract LP",
    link: "https://bscscan.com/address/0x5aDF0C6446A4A50a1A8951aFA6F3bF998071242d",
  },
  {
    text: "Get ONI/BNB",
    link: "https://app.oniprotocol.io/liquidity",
  },
];
export default MiningStake;
